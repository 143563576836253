/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { CameraIcon } from '@heroicons/react/solid'
import InternetWhy from '../../../images/internetWhy.jpg'

export default function Example() {
  return (
    <div className="bg-white overflow-hidden" id='why'>
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            {/* <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Case Study</h2> */}
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-blue-600 sm:text-4xl" data-aos="fade-right">
                Pourquoi faire un site web ?
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
            </svg>
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none mb-10" data-aos-anchor-placement="center-bottom" data-aos="zoom-in">
                  <img
                    className="rounded-lg shadow-lg object-cover object-center"
                    src={InternetWhy}
                    alt="Whitney leaning against a railing on a downtown street"
                    width={1184}
                    height={1376}
                  />
                </div>
                {/* <figcaption className="mt-3 flex text-sm text-gray-500">
                  <CameraIcon className="flex-none w-5 h-5 text-gray-400" aria-hidden="true" />
                  <span className="ml-2">Photograph by Marcus O’Leary</span>
                </figcaption> */}
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="text-base max-w-prose mx-auto lg:max-w-none">
              <p className="text-lg text-gray-500" data-aos="fade-right">
                Dans un monde où la concurrence ne cesse de grandir, il est devenu difficile de se demarquer par le simple bouche à oreille. 
                Par conséquent la création d'un site internet est indispensable pour se faire remarquer en Belgique en laissant son emprunte sur le Web.
              </p>
            </div>
            <div className="mt-5 prose prose-blue text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              <p data-aos="fade-right">
                C'est pourquoi Netwever vous offre une opportunité de partager votre projet avec le reste du monde à un prix defiant 
                toute concurrence et dans les delais les plus court grâce à un site web avec un responsive design entièrement mobile friendy.
              </p>
              <p data-aos="fade-right">
                Cependant nous n'omettons pas que votre projet a du potentiel et grandira dans le future. 
                De ce fait, vous aurez toujours l'occasion d'agrandir votre site à un prix abordable.
              </p>
              <p data-aos="fade-right">
                En faisant l'acquisition d'un site internet, vous aurez la possibilité de: 
              </p>
              <ul role="list">
                <li data-aos="fade-right">Une espace dédié et personnalisé pour communiquer sur votre marque.</li>
                <li data-aos="fade-right">La possibilité d'améliorer votre visibilité et de trouver de nouveaux clients.</li>
                <li data-aos="fade-right">La possibilité de vendre en ligne sans perdre de temps.</li>
                <li data-aos="fade-right">La possibilité de recevoir les paiements directement en ligne sans intervention de votre part.</li>
                <li data-aos="fade-right">Une plateforme disponible 7 jours sur 7 et 24 heures sur 24 pour vos prospects</li>
                <li data-aos="fade-right">Facilement personnalisable, modifiable (comparé aux docs papiers)</li>
                <li data-aos="fade-right">Développe la confiance des clients et votre capacité de Service Après Vente</li>
                <li data-aos="fade-right">Automatise certaines tâches pour vous, par exemple la gestion des stocks ou même la réservation des rendez-vous dans votre agenda</li>

              </ul>
              {/* <p>
                Rhoncus nisl, libero egestas diam fermentum dui. At quis tincidunt vel ultricies. Vulputate aliquet
                velit faucibus semper. Pellentesque in venenatis vestibulum consectetur nibh id. In id ut tempus
                egestas. Enim sit aliquam nec, a. Morbi enim fermentum lacus in. Viverra.
              </p>
              <h3>How we helped</h3>
              <p>
                Tincidunt integer commodo, cursus etiam aliquam neque, et. Consectetur pretium in volutpat, diam.
                Montes, magna cursus nulla feugiat dignissim id lobortis amet. Laoreet sem est phasellus eu proin massa,
                lectus. Diam rutrum posuere donec ultricies non morbi. Mi a platea auctor mi.
              </p>
              <p>
                Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum urna sed consectetur neque tristique
                pellentesque. Blandit amet, sed aenean erat arcu morbi.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
