/* This example requires Tailwind CSS v2.0+ */
// import { NewspaperIcon, PhoneIcon, SupportIcon } from '@heroicons/react/outline'

import chromeLogo from '../../../images/chrome.png'
import windowsLogo from '../../../images/windows.png'
import androidLogo from '../../../images/android.png'

import Home1 from '../../../images/home1.jpg'


const supportLinks = [
  {
    name: 'Site internet',
    href: '#',
    description:
      'Nous offrons des sites internet ayant une gamme de prix pouvant satisfaire tous les budgets. Allant de la petite bourse à la grande fortune.',
    icon: chromeLogo,
  },
  {
    name: 'Application Android',
    href: '#',
    description:
      'Chez Netwever, nous sommes au top de la modernité et vous offrons la possibilité de vous concevoir une application smartphone.',
    icon: androidLogo,
  },
  {
    name: 'Application Windows',
    href: '#',
    description:
      'Il est toujours compliqué de trouver une application qui remplisse parfaitement nos besoins sur ordinateur. Nous serons donc heureux de régler ce problème.',
    icon: windowsLogo,
  },
]

export default function Example() {
  return (
    <div className="bg-white">
      {/* Header */}
      <div className="relative pb-32 bg-gray-800">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src={Home1}
            alt=""
          />
          <div className="absolute inset-0 bg-gray-800 mix-blend-multiply" aria-hidden="true" />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl" data-aos="fade-right">Nos services</h1>
          <p className="mt-6 max-w-3xl text-xl text-gray-300" data-aos="fade-up">
            Chez netwever, nous ne nous limitons pas seulement à la création du site internet et offrons une multitude de 
            services allant de l'application pour smartphone au programme pour Windows.
          </p>
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h2 className="sr-only" id="contact-heading">
          Contact us
        </h2>
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
          {supportLinks.map((link) => (
            <div key={link.name} className="flex flex-col bg-white rounded-2xl shadow-xl" data-aos="zoom-out">
              <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                <div className="absolute top-0 p-5 inline-block bg-indigo-600 rounded-xl shadow-lg transform -translate-y-1/2">
                  {/* <link.icon className="h-6 w-6 text-white" aria-hidden="true" /> */}
                  <img src={link.icon} className="h-6 w-6 text-white"/>
                </div>
                <h3 className="text-xl font-medium text-gray-900">{link.name}</h3>
                <p className="mt-4 text-base text-gray-500">{link.description}</p>
              </div>
              <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                <a href={link.href} className="text-base font-medium text-indigo-700 hover:text-indigo-600">
                  En savoir plus<span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}
