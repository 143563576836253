/* This example requires Tailwind CSS v2.0+ */
import {useContext } from 'react'
import {contactMdodalStatus} from '../../../functions/Functions'


const navigation = [
    { name: 'Acceuil', href: '/fr/acceuil' },
    { name: 'Site internet', href: '/fr/websites' },
    { name: 'Application smartphone', href: '/fr/smartphone' },
    { name: 'Application Windows', href: '/fr/application' },
  ]

  import Logo from '../../../images/logo.png'
  
  export default function Example() {
    const {boxStatus, setBoxStatus} = useContext(contactMdodalStatus)

    const OpenBox = () => {
      setBoxStatus(true)
    }
    return (
      <header className="bg-red-600">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
          <div className="w-full py-6 flex items-center justify-between border-b border-red-500 lg:border-none">
            <div className="flex items-center">
              <a href="/fr/acceuil">
                <span className="sr-only">Workflow</span>
                <img
                  className="h-8 sm:h-10 w-auto"
                  src={Logo}
                  alt=""
                />
              </a>
              <div className="hidden ml-10 space-x-8 lg:block">
                {navigation.map((link) => (
                  <a key={link.name} href={link.href} className="text-base font-medium text-white hover:text-red-50">
                    {link.name}
                  </a>
                ))}
              </div>
            </div>
            <div className="ml-10 space-x-4">
              {/* <a
                href="#"
                className="inline-block bg-red-500 py-2 px-4 border border-transparent rounded-md text-base font-medium text-white hover:bg-opacity-75"
              >
                Sign in
              </a> */}
              <button
                onClick={OpenBox}
                className="animate-beating inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700"
              >
                Nous contacter
              </button>
            </div>
          </div>
          <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
            {navigation.map((link) => (
              <a key={link.name} href={link.href} className="text-base font-medium text-white hover:text-red-50">
                {link.name}
              </a>
            ))}
          </div>
        </nav>
      </header>
    )
  }
  