import React from 'react' 
import MenuFr from './sections/Menu'
import HeroFr from './sections/Hero'

const Application = () => {
    return(
        <React.Fragment>
            <MenuFr/>
            <HeroFr/>
        </React.Fragment>
    )
}

export default Application