import React,{useContext, createContext, useState} from 'react' 
import {BrowserRouter, Switch, Route} from 'react-router-dom'

import HomeFr from '../french/home/Home'
import WebsitesFr from '../french/websites/Websites'
import ApplicationFr from '../french/applications/Application'
import SmartphoneFr from '../french/smartphone/Smartphone'
import FooterFr from '../french/components/Footer'
import ContactFr from '../french/components/Contact'

import {contactMdodalStatus} from '../functions/Functions'

import AOS from 'aos';
AOS.init({duration:1000});
import 'aos/dist/aos.css'


const Router = () => {
    const [boxStatus, setBoxStatus] = useState(false)
    return(
        <BrowserRouter>
            <contactMdodalStatus.Provider value={{boxStatus, setBoxStatus}}>
            <ContactFr/>
            <Switch>
                <Route exact path="/fr/acceuil" component={() => <HomeFr/>}/>
                <Route exact path="/fr/websites" component={WebsitesFr}/>
                <Route exact path="/fr/smartphone" component={SmartphoneFr}/>
                <Route exact path="/fr/application" component={ApplicationFr}/>
                <Route path="/" component={HomeFr}/>
            </Switch>
            <FooterFr/>
            </contactMdodalStatus.Provider>
        </BrowserRouter>
    )
}

export default Router