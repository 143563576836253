import React from 'react' 
import HeroFr from './sections/Hero'
import Whyfr from './sections/Why'
import PricesFr from './sections/Prices'
import CtaFr from './sections/Cta'
import ServerFr from './sections/Server'
import Cta2Fr from './sections/Cta2'
import ModuleFr from './sections/Module'
import MenuFr from './sections/Menu'


const Websites = () => {
    return(
        <React.Fragment>
            <MenuFr/>
            <HeroFr/>
            <Whyfr/>
            <CtaFr/>
            <PricesFr/>
            <ServerFr/>
            <Cta2Fr/>
            <ModuleFr/>
        </React.Fragment>
    )
}

export default Websites