/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/outline'

const features = [
  {
    name: `SECTION SUPPLÉMENTAIRE \\€112,99`,
    description: `Si vous souhaitez ajouter du contenu sur votre site mais n'avez pas besoin d'un page entiere le faire, ajouter une section sur un page déja existante est la solution que vous chercher.`,
  },
  {
    name: `PAGES SUPPLÉMENTAIRE \\€226,99`,
    description: `Si le contenu que vous desirez integrer dans votre site est bien trop imposant pour une simple section sur une page existante, vous trouverez la réponse à votre problème en ajoutant une page entière à votre site internet.`,
  },
  {
    name: `FORMULAIRE DE CONTACTE \\€453,99`,
    description: `Il n'est pas toujours évident d'être disponible pour repondre au téléphone lorque l'on a un commerce très actif. Un formulaire de contact vous permettra de repondre au message de vos client, lorsque votre emploi du temps vous le permettra.`,
  },
  {
    name: `BASE DE DONNÉES \\€680,99`,
    description: `Si vous estimez que votre site est bien trop grand et nécessite une meilleur organisation, une base de données vous sera indispensable.`,
  },
  {
    name: `SYSTÈME DE CONNECTION \€908,99`,
    description: `Si vous avez trop de client et avez du mal à la suivre, surtout lorsque ces derniers achetent sur votre site. Un système d'inscription est exactement ce qu'il vous faut.`,
  },
//   {
//     name: 'Reporting',
//     description: 'Magna a vel sagittis aliquam eu amet. Et lorem auctor quam nunc odio. Sed bibendum.',
//   },
//   {
//     name: 'Calendars',
//     description: 'Sed mi, dapibus turpis orci posuere integer. A porta viverra posuere adipiscing turpis.',
//   },
//   {
//     name: 'Mobile app',
//     description: 'Quisque sapien nunc nisl eros. Facilisis sagittis maecenas id dignissim tristique proin sed.',
//   },
]

export default function Example() {
  return (
    <div className="bg-white" id='module'>
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-blue-600" data-aos="fade-down">Nos modules</h2>
          <p className="mt-4 text-lg text-gray-500" data-aos="fade-up">
            Pour garantir une flexibilité optimale, vous pouvez à tout moment agrandir votre site internet !
          </p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="relative" data-aos="zoom-in">
              <dt>
                {/* <CheckIcon className="absolute h-6 w-6 text-blue-500" aria-hidden="true" /> */}
                <p className="ml-9 text-lg leading-6 font-medium text-blue-900">{feature.name}</p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
