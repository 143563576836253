/* This example requires Tailwind CSS v2.0+ */
import Hero from '../../../images/internetHero.jpg'
export default function Example() {
    return (
      <div className="relative bg-blue-800">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src={Hero}
            alt=""
          />
          <div className="absolute inset-0 bg-blue-800 mix-blend-multiply" aria-hidden="true" />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl" data-aos="fade-right">Site Internet</h1>
          <p className="mt-6 text-xl sm:text-3xl  text-blue-100 max-w-3xl" data-aos="fade-up">
            La solution idéale pour toute entreprise souhaitant débuter son voyage vers le succès !
          </p>
        </div>
      </div>
    )
  }
  