import React from 'react' 

import HeroFr from './sections/Hero'
import WhyUsFr from './sections/WhyUs'
import Incentive from './sections/Incentive'
import ServicesFr from './sections/Services'
import Cta from './sections/Cta'

const Home = () => {
    return(
        <React.Fragment>
            <HeroFr/>
            <Incentive/>
            <ServicesFr/>
            <Cta/>
            <WhyUsFr/>
        </React.Fragment>
    )
}

export default Home