/* This example requires Tailwind CSS v2.0+ */
import {useContext } from 'react'
import {contactMdodalStatus} from '../../../functions/Functions'


export default function Example() {
  const {boxStatus, setBoxStatus} = useContext(contactMdodalStatus)

  const OpenBox = () => {
    setBoxStatus(true)
  }
  return (
    <div className="bg-blue-700">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="block">Vous êtes convaincu ?</span>
          <span className="block mt-8 sm:mt-0">Contactez-nous dès maintenant !</span>
        </h2>
        {/* <p className="mt-4 text-lg leading-6 text-blue-200">
          Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec.
        </p> */}
        <button
          onClick={OpenBox}
          className="animate-beating mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50 sm:w-auto"
        >
          Nous contacter
        </button>
      </div>
    </div>
  )
  }
  