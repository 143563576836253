/* This example requires Tailwind CSS v2.0+ */
import {useContext } from 'react'
import {contactMdodalStatus} from '../../../functions/Functions'


export default function Example() {
  const {boxStatus, setBoxStatus} = useContext(contactMdodalStatus)

  const OpenBox = () => {
    setBoxStatus(true)
  }
  return (
    <div className="bg-blue-50">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold tracking-light text-gray-900 sm:text-4xl">
          <span className="block">Vous êtes convaincu ?</span>
          <span className="block text-blue-600">Contactez-nous dès maintenant !</span>
        </h2>
        <button
          onClick={OpenBox}
          className="animate-wiggle mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 sm:w-auto"
        >
          Nous contacter
        </button>
      </div>
    </div>
  )
  }
  