/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
    ],
  }
  ```
*/


import Home2 from '../../../images/home2.jpg'
export default function Example() {
    return (
      <div className="relative bg-white">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <img
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src={Home2}
              alt=""
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              {/* <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Work with us</h2> */}
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-indigo-600 sm:text-4xl" data-aos="fade-right">
                Pourquoi nous ?
              </h3>
              <p className="mt-8 text-lg text-gray-500" data-aos="fade-right">
                Chez Netwever, vous êtes notre priorité. Et nous nous efforcerons de trouver l'offre qui 
                convient le mieux à vos besoins tout en restant dans les limites de votre budget.
              </p>
              <div className="mt-5 prose prose-indigo text-gray-500" data-aos="fade-right">
                <p>
                  Nous sommes concient que l'acquisition de votre application (aussi bien web, android ou Windows) est d'une grande importance pour 
                  votre entreprise et feront de notre mieux pour vous la pourvoir dans les délais les plus brefs.
                </p>
                {/* <p>
                  Bibendum eu nulla feugiat justo, elit adipiscing. Ut tristique sit nisi lorem pulvinar. Urna, laoreet
                  fusce nibh leo. Dictum et et et sit. Faucibus sed non gravida lectus dignissim imperdiet a.
                </p> */}
                <p data-aos="fade-right">
                  Les domaines actuels dans lesquels nous sommes à même de vous assister sont les suivants:
                </p>
                <ul role="list">
                  <li data-aos="fade-right">Création du site internet.</li>
                  <li data-aos="fade-right">Création d'application smartphone (Pour Android).</li>
                  <li data-aos="fade-right">Création d'application Windows.</li>
                </ul>
                <p data-aos="fade-right">
                  Si vous desirez plus d'information, n'hesitez pas a nous contacter !
                </p>
                {/* <h3>How we’re different</h3>
                <p>
                  Tincidunt integer commodo, cursus etiam aliquam neque, et. Consectetur pretium in volutpat, diam.
                  Montes, magna cursus nulla feugiat dignissim id lobortis amet. Laoreet sem est phasellus eu proin massa,
                  lectus. Diam rutrum posuere donec ultricies non morbi. Mi a platea auctor mi.
                </p>
                <p>
                  Mauris ullamcorper imperdiet nec egestas mi quis quam ante vulputate. Vel faucibus adipiscing lacus,
                  eget. Nunc fermentum id tellus donec. Ut metus odio sit sit varius non nunc orci. Eu, mi neque, ornare
                  suspendisse amet, nibh. Facilisi volutpat lectus id sapien dis mauris rhoncus. Est rhoncus, interdum
                  imperdiet ac eros, diam mauris, tortor. Risus id sit molestie magna.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  